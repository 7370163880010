:root {
  --black: #171321;
  --white: #f7f8fa;
  --lightgrey: #f3f3f3;
  --mono: 'Arial';
  --sans: Arial;
  --twd-blue: #02adef;
  --twd_aqua: rgb(0, 173, 239);
  --twd_aqua_darker: rgb(0, 146, 201);
  --twd_navy: rgb(0, 48, 107);
  --twd_navy_darker: rgb(0, 35, 78);
  --twd_red: rgb(221, 028, 026);
  --twd_red_darker: rgb(191, 23, 22);
  --twd_white: rgb(255, 255, 255);
  --twd_grey: rgb(233, 233, 233);
  --twd_border_grey: rgb(221, 221, 221);
}

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  border-radius: 7px;
  border: 1px solid lightgray;
  box-shadow: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #707070;
}